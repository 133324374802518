import $ from 'jquery';
import _ from 'lodash'
import './recaptcha'

$(window).on('load',function () {
    window.setTimeout(function () {
        let $slideLinks = $('.tp-caption.sft.slidelink a');

        if($slideLinks.length > 0) {
            $.each($slideLinks, function (index, element) {
                let $element = $(element);
                let $srOnly = $('<span></span>');
                $srOnly.addClass('sr-only');
                $srOnly.html('Slide Link');
                if($element.text() === "") {
                    $element.append($srOnly);
                }

            });
        }

        let $salePointInput = $('.io.cms-id-65 .bootstrap-filestyle input, .io.cms-id-66 .bootstrap-filestyle input');
        if($salePointInput.length>0){
            let $uniqId = _.uniqueId('fileinput');
            $salePointInput.attr('id', $uniqId);
            $salePointInput.wrap("<label for='" + $uniqId + "'></label");
            $('label[for="' + $uniqId + '"]').append("<span class='sr-only'>Fileinput</span>");
        }

    },2000);

    let $jsIncreaseProductQuantity = $('.js-increase-product-quantity');
    let $jsDecreaseProductQuantity = $('.js-decrease-product-quantity');
    let $jsTouchspinUp = $('.js-touchspin.bootstrap-touchspin-up');
    let $jsTouchspinDown = $('.js-touchspin.bootstrap-touchspin-down');
    let $payWithPaymentOption2 = $('button#pay-with-payment-option-2');

    if($jsIncreaseProductQuantity.length >0){
        $('.js-increase-product-quantity').append('<span class="sr-only">increase</span>');
    }

    if($jsDecreaseProductQuantity.length >0){
        $('.js-decrease-product-quantity').append('<span class="sr-only">decrease</span>');
    }

    if($jsTouchspinUp.length >0){
        $('.js-touchspin.bootstrap-touchspin-up').append('<span class="sr-only">increase</span>');
    }

    if($jsTouchspinDown.length >0){
        $('.js-touchspin.bootstrap-touchspin-down').append('<span class="sr-only">decrease</span>');
    }
    if($payWithPaymentOption2.length > 0) {
        $('button#pay-with-payment-option-2').append('<span class="sr-only">submit</span>');
    }
});



