import $ from 'jquery';

var grecaptchaSelector = '[name="g-recaptcha-response"]';

var waitForEl = function (selector, callback) {
  if (jQuery(selector).length) {
    callback();
  } else {
    setTimeout(function () {
      waitForEl(selector, callback);
    }, 500);
  }
};

waitForEl(grecaptchaSelector, function () {
  console.log("Adding labels to recaptcha form for accessibility");
  var $recaptcha = $(grecaptchaSelector);
  $recaptcha.each(function (index, element) {
    var $element = $(element);
    // console.log($element);
    $element.attr('aria-label', 'g-recaptcha-response');
  })
});