import '../scss/ioweb-custom.scss';
import WebFont from 'webfontloader';
import $ from 'jquery';
import './identity';
import './backorder';
import './wcag';
import './html5';
import './homepage-carousel';
import './product-scroll';

$.noConflict();
$(document).ready(function () {
    WebFont.load({
        google: {
            families: ['Open Sans']
        }
    });
});

